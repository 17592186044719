import { createApp } from 'vue'
import { createPinia } from 'pinia'

import '@lyka/tailwind-preset/globals.css'

import { segmentPlugin } from '@lyka/vue-common/plugins/segment'
import { frontChatPlugin } from '@lyka/vue-common/plugins/frontChat'
import { recaptchaPlugin } from '@lyka/vue-common/plugins/recaptcha'

import App from './App.vue'

import { debugPlugin } from './plugins/debug'
import { submitDirective } from './directives/submit'
import env from './env'

const app = createApp(App)

app.directive('submit', submitDirective)
app.use(segmentPlugin, { writeKey: env.VITE_SEGMENT_PHOENIX_WRITE_KEY })
app.use(frontChatPlugin, { chatId: env.VITE_FRONT_CHAT_ID, hideOnMobile: true })
app.use(debugPlugin)
app.use(recaptchaPlugin, { siteKey: env.VITE_RECAPTCHA_SITE_KEY })

// Have to use the following format to load Pinia for dev-tools to work:
// https://github.com/vuejs/devtools/issues/1839
const pinia = createPinia()

app.use(pinia).mount('#app')
