import flagsmith from 'flagsmith'
import type { IFlags } from 'flagsmith/types'
import { shallowRef } from 'vue'

const EXPERIMENT_FLAG_PREFIX = 'experiment-'

const ExperimentTypes = ['known', 'anonymous'] as const

export type Experiments = Record<string, string>

export const experimentsFromFlags = (
  flags: IFlags<string>,
  experimentType?: (typeof ExperimentTypes)[number],
): Experiments => {
  const experiments: Experiments = {}

  for (const [flagName, { value, enabled }] of Object.entries(flags)) {
    const isExperimentFlag = flagName.startsWith(EXPERIMENT_FLAG_PREFIX)

    if (isExperimentFlag && enabled && typeof value === 'string') {
      // Strip the experiment prefix off the name
      const experimentName = flagName.replace(EXPERIMENT_FLAG_PREFIX, '')

      // Exclude experiments without a name
      if (!experimentName) {
        continue
      }

      // Exclude experiments that don't match the required type
      if (experimentType && !experimentName.startsWith(experimentType)) {
        continue
      }

      // Exclude experiments that have a type but we want experiments without types
      if (!experimentType && ExperimentTypes.some((type) => experimentName.startsWith(type))) {
        continue
      }

      experiments[experimentName] = value
    }
  }

  return experiments
}

let loaded: () => void

const loading = shallowRef<Promise<void>>(new Promise((resolve) => (loaded = resolve)))

const init = async (anonymousOrUserId?: string): Promise<void> => {
  // https://docs.flagsmith.com/clients/javascript
  loading.value = flagsmith.init({
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_KEY,
    identity: anonymousOrUserId,
  })

  return loading.value
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useFlagsmith = () => {
  const loadFlags = async (anonymousOrUserId?: string): Promise<IFlags<string>> => {
    await init(anonymousOrUserId)

    loaded()

    return flagsmith.getAllFlags()
  }

  /**
   * Contrary to the types provided by Flagsmith `getAllFlags` can return null in certain circumstances
   */
  const identify = async (userId: string): Promise<IFlags<string> | null> => {
    await flagsmith.identify(userId)

    return flagsmith.getAllFlags()
  }

  const getFlags = (): IFlags => {
    return flagsmith.getAllFlags()
  }

  const getFlag = (key: string): string | undefined => {
    return flagsmith.getValue(key)
  }

  const isEnabled = (flagName: string): boolean => {
    const flags = getFlags()

    if (!flags) {
      return false
    }

    if (flagName in flags === false) {
      return false
    }

    return getFlags()[flagName]?.enabled ?? false
  }

  const getFlagAsync = async (key: string): Promise<string | undefined> => {
    await loading.value

    return getFlag(key)
  }

  const isEnabledAsync = async (flagName: string): Promise<boolean> => {
    await loading.value

    return isEnabled(flagName)
  }

  return {
    identify,
    loadFlags,
    getFlags,
    getFlag,
    getFlagAsync,
    isEnabledAsync,
    isEnabled,
  }
}
