<script setup lang="ts">
import { LykaButton, LykaFieldList, LykaInput, LykaMessage } from '@lyka/ui'
import { ref } from 'vue'
import { pluralize } from '@lyka/utils'
import type { DogEntity } from '@lyka/bab-api-contracts/src/bab'
import StepHeading from '@/components/StepHeading.vue'
import MiniHr from '@/components/common/MiniHr.vue'
import { useFrontChat } from '@/composables/useFrontChat'
import { ymd } from '@/__test__/utils/ymd'
import { birthdayToAge } from '@/utils/birthdayConversion'

const props = defineProps<{
  dog: DogEntity
  index: number
}>()

const emit = defineEmits<{
  (e: 'update', value: string, approx: boolean): void
}>()

const MAX_AGE_YEARS = 30
const MIN_RECOMMENDED_AGE_MONTHS = 1

enum BirthdayType {
  Date = 'date',
  Age = 'age',
}

const now = new Date()

const birthdayType = ref<BirthdayType>(props.dog.dateOfBirthIsApproximate ? BirthdayType.Age : BirthdayType.Date)
const birthday = ref<string>(props.dog.dateOfBirth ?? '')

const validate = (dob?: string): boolean => {
  if (!dob) {
    return false
  }

  const dobMs = new Date(dob).getTime()

  return !isNaN(dobMs)
}

const ageToDob = (years: number, months: number): string => {
  const day = now.getDate()

  const month = now.getMonth() - months
  const year = now.getFullYear() - years

  return new Date(year, month, day).toISOString().split('T')[0]!
}

const ageYears = ref<number>(birthdayToAge(props.dog.dateOfBirth).years)
const ageMonths = ref<number>(birthdayToAge(props.dog.dateOfBirth).months)

const minDate = ymd(new Date(now.getFullYear() - MAX_AGE_YEARS))
const maxDate = ymd(now)

const updateAgeType = (): void => {
  birthdayType.value = BirthdayType.Age
  birthday.value = ageToDob(ageYears.value, ageMonths.value)

  if (validate(birthday.value)) {
    emit('update', birthday.value, true)
  }
}

const updateDateType = (): void => {
  birthdayType.value = BirthdayType.Date

  const valid = validate(birthday.value)

  if (valid) {
    const { years, months } = birthdayToAge(birthday.value)

    ageYears.value = years
    ageMonths.value = months

    emit('update', birthday.value, false)
  } else {
    ageYears.value = 0
    ageMonths.value = 0
  }
}

const belowRecommendedAge = (): boolean => {
  const { years, months } = birthdayToAge(birthday.value)

  return years === 0 && months <= MIN_RECOMMENDED_AGE_MONTHS
}
</script>

<template>
  <div>
    <MiniHr v-if="index > 0" />

    <StepHeading> How old is {{ dog.name }}? </StepHeading>

    <div class="tw-flex tw-justify-center tw-mb-8">
      <div class="tw-flex tw-flex-row tw-bg-cream tw-p-2 tw-p tw-rounded-full tw-w-min tw-space-x-2">
        <LykaButton
          :id="`dog-birthday-btn-${index}`"
          :variant="birthdayType === BirthdayType.Date ? 'alt' : 'subtle'"
          :transparent="birthdayType === BirthdayType.Date ? false : true"
          @click="updateDateType()"
          >Date of birth
        </LykaButton>

        <LykaButton
          :id="`dog-age-btn-${index}`"
          :variant="birthdayType === BirthdayType.Age ? 'alt' : 'subtle'"
          :transparent="birthdayType === BirthdayType.Age ? false : true"
          @click="updateAgeType()"
          >Approximate age
        </LykaButton>
      </div>
    </div>

    <LykaInput
      v-if="birthdayType === BirthdayType.Date"
      v-model.string="birthday"
      label="Date of birth"
      hide-label
      class="date-input"
      type="date"
      :name="`birthday-${index}`"
      :required="birthdayType === BirthdayType.Date"
      :error-message="`Please enter ${dog.name}'s birthday`"
      :min="minDate"
      :max="maxDate"
      @change="updateDateType"
    />

    <LykaFieldList v-else>
      <LykaInput
        v-model.number="ageYears"
        :name="`age-years-${index}`"
        placeholder="Years"
        type="number"
        min="0"
        max="30"
        suffix="Years"
        pattern="[0-9]*"
        :required="birthdayType === BirthdayType.Age && !ageMonths"
        :error-message="`Please enter ${dog.name}'s age`"
        show-zero
        @input="updateAgeType"
      />

      <LykaInput
        v-model.number="ageMonths"
        :name="`age-months-${index}`"
        placeholder="Months"
        type="number"
        min="0"
        max="11"
        suffix="Months"
        pattern="[0-9]*"
        :required="birthdayType === BirthdayType.Age && !ageYears"
        hide-error
        show-zero
        @input="updateAgeType()"
      />
    </LykaFieldList>

    <LykaMessage v-if="belowRecommendedAge()" class="tw-mt-4">
      <p>
        Lyka is recommended for puppies that are at least {{ MIN_RECOMMENDED_AGE_MONTHS }}
        {{ pluralize(MIN_RECOMMENDED_AGE_MONTHS, 'month') }} of age.
      </p>
      <p>
        If you would like advice about weaning puppies onto Lyka or our breeder partnership program please
        <button type="button" class="tw-link" @click="useFrontChat().show()">contact us</button> to talk to our
        veterinary experts.
      </p>
    </LykaMessage>
  </div>
</template>

<style lang="postcss">
.date-input::-webkit-datetime-edit-year-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-month-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-day-field {
  text-transform: uppercase;
}

.date-input::-webkit-datetime-edit-year-field:focus {
  color: inherit;
}

.date-input::-webkit-datetime-edit-month-field:focus {
  color: inherit;
}

.date-input::-webkit-datetime-edit-day-field:focus {
  color: inherit;
}

.date-input::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23005648" d="M5.3 21.5c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 0 1 3.5 19.7V6.3c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h1.4V3.125c0-.217.07-.396.212-.538a.731.731 0 0 1 .538-.212c.233 0 .42.075.562.225a.77.77 0 0 1 .213.55V4.5H15.8V3.1c0-.2.075-.371.225-.513a.741.741 0 0 1 .525-.212c.217 0 .396.075.537.225a.74.74 0 0 1 .213.525V4.5h1.4c.5 0 .925.175 1.275.525.35.35.525.775.525 1.275v13.4c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H5.3Zm0-1.5h13.4a.292.292 0 0 0 .213-.087A.292.292 0 0 0 19 19.7v-9.4H5v9.4c0 .083.03.154.088.213A.289.289 0 0 0 5.3 20ZM5 8.8h14V6.3a.289.289 0 0 0-.087-.212A.29.29 0 0 0 18.7 6H5.3a.287.287 0 0 0-.212.088A.287.287 0 0 0 5 6.3v2.5Z"/></svg>');
}
</style>
