export const pluralize = (quantity: number | undefined, singular: string, plural?: string): string => {
  plural ??= `${singular}s`

  if (!quantity) {
    return plural
  }

  if (Math.abs(quantity) === 1) {
    return singular
  }

  if (!quantity) {
    return plural
  }

  return plural
}
