import type { App, Plugin } from 'vue'

declare global {
  interface Window {
    FrontChat?: (event: string, options?: { chatId: string; useDefaultLauncher: boolean }) => void
  }
}

const isMobile = (): boolean => document.body.clientWidth < 768

export const frontChatPlugin: Plugin = {
  install(_: App, { chatId, hideOnMobile }: { chatId?: string; hideOnMobile?: boolean }) {
    if ('FrontChat' in window) {
      return
    }

    if (chatId === undefined) {
      return
    }

    const script = document.createElement('script')
    const src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js'

    Object.assign(script, {
      src,
    })

    document.body.appendChild(script)

    script.onload = (): void => {
      const useDefaultLauncher = hideOnMobile ? !isMobile() : true

      window.FrontChat?.('init', { chatId, useDefaultLauncher })
    }
  },
}
