import { z } from 'zod'

const envSchema = z.object({
  VITE_SEGMENT_PHOENIX_WRITE_KEY: z.string(),
  VITE_TRUSTPILOT_BUSINESSUNIT_ID: z.string(),
  VITE_TRUSTPILOT_BUSINESSUNIT_NAME: z.string(),
  VITE_GTM_ID: z.string(),
  VITE_FLAGSMITH_ENVIRONMENT_KEY: z.string(),
  VITE_FRONT_CHAT_ID: z.string(),
  VITE_RECAPTCHA_SITE_KEY: z.string(),
  VITE_EVENT_GATEWAY_URL: z.string(),
  VITE_ENABLE_CRAWLERS: z.string().optional(),
})

const env = envSchema.parse(import.meta.env ?? process.env)

export default env
